<template>
  <view-item title="配方验证">
    <en-card class="h-full" body-class="h-full">
      <tabs-maintain
        v-model="tabs.active"
        :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['FMLSTATUS']) }"
        :props="{ label: 'message', value: 'code' }"
        :method="table.get"
      ></tabs-maintain>

      <flex-box>
        <template #default="{ height }">
          <service-formula-table :data="table.data" :loading="table.loading" :height="height" pagination :paging="table.paging" :method="table.get">
            <template #prepend>
              <en-table-column label="操作">
                <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDto'] }">
                  <en-button type="primary" link @click="table.operation.check.click(row)">查看</en-button>
                </template>
              </en-table-column>
            </template>
          </service-formula-table>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <service-formula-detail
    v-model="detail.visible"
    :data="detail.data"
    show-discard-btn
    show-preliminary-audit-btn
    show-audit-btn
    @confirm="detail.confirm"
  ></service-formula-detail>
</template>

<script lang="ts">
import ServiceFormulaTable from '@spray/components/service-formula-table.vue'
import ServiceFormulaDetail from '@spray/components/service-formula-detail.vue'

export default factory({
  components: { ServiceFormulaTable, ServiceFormulaDetail },

  config: {
    children: {
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/formula',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        },
        children: {
          operation: {
            check: {
              click(row: EnospraySprayDefinitions['SprayFormulaDto']) {
                this.detail.data = row
                this.detail.visible = true
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        data: {} as EnospraySprayDefinitions['SprayFormulaDto'],
        confirm() {
          this.table.get()
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
